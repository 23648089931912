export default {
  data() {
    return {
      mobile: false,
      width: 0
    };
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    }
  },
  watch: {
    width() {
      if (this.width < 767) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
    if (this.width < 767) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWidth);
  }
};
