<template>
  <form v-if="!show" @submit.prevent="addLead" class="orders__send">
    <div class="order">
      <div
        class="order__title"
        :style="
          mobile ? `margin: 20px; padding-top: 20px` : `padding-top: 20px`
        "
      >
        Собранный заказ
      </div>
      <div class="order__form" :style="mobile ? `margin: 15px 5px 0 5px;` : ``">
        <div class="order__form--block">
          <div class="order__form--date">
            <form-input
              v-model="phone"
              label="Телефон"
              v-maska="{
                mask: '+H ### ###-##-##',
                tokens: {
                  H: { pattern: /[0-9]/, transform: (char) => (char = 7) }
                }
              }"
              placeholder="+7 495 979-03-00"
              icon="phone"
              required
            ></form-input>
          </div>
          <div class="order__form--date">
            <form-input
              v-model="email"
              type="email"
              label="Email"
              placeholder="ivanov@mail.ru"
              icon="mail"
              required
            ></form-input>
          </div>
        </div>
      </div>
      <div class="order__form" :style="mobile ? `margin: 15px 0 0 0` : ``">
        <div
          v-for="(order, index) in savedOrders"
          :key="index"
          class="order__row"
          :style="[
            orderId === index ? `border: 1px solid black` : ``,
            mobile ? `padding: 0` : ``
          ]"
          @click="editOrder(index)"
        >
          <div
            v-if="order.carImage"
            class="order__row--image"
            :style="mobile ? `margin: 2px` : ``"
          >
            <img
              :src="require(`@/assets/images/cars/${order.carImage}`)"
              alt="Машина"
            />
          </div>
          <img
            class="order__row--icon"
            :style="mobile ? `margin: 0 0 0 5px` : ``"
            src="@/assets/images/shevron-left.svg"
            @click.stop="changeOrderCount(1, order.carCount, index)"
          />
          <div class="order__row--count">{{ order.carCount }}</div>
          <img
            class="order__row--icon right"
            :style="mobile ? `margin: 0 10px 0 0` : ``"
            src="@/assets/images/shevron-right.svg"
            @click.stop="changeOrderCount(2, order.carCount, index)"
          />
          <div :style="mobile ? `text-transform: uppercase` : ``">
            {{ mobile ? order.carTypeShort : order.carType }} |
            {{ mobile ? order.carNameShort : order.carName }}
          </div>
          <div
            class="order__row--sum"
            :style="mobile ? `right: 65px; top: 13px` : ``"
          >
            {{ roundingNumber(order.carSum, 1) }} &#8381;
          </div>
          <img
            class="order__row--edit"
            :style="mobile ? `right: 30px; top: 11px` : ``"
            src="@/assets/images/edit.svg"
            alt="Редактировать"
            title="Редактировать заказ"
          />
          <img
            class="order__row--remove"
            :style="mobile ? `right: 5px; top: 12px` : ``"
            src="@/assets/images/delete.svg"
            alt="Удалить"
            title="Удалить заказ"
            @click.stop="removeOrder(index)"
          />
        </div>
      </div>
      <div v-if="sumLead" :class="mobile ? `cost__mobile` : `order__form`">
        <div class="order__form--cost">
          Итого:
          <h2>{{ sumLead }}</h2>
          &#8381;
        </div>
      </div>
    </div>
    <div class="save__button">
      <div v-if="mobile" @click="backToPage" class="button__back">
        &lt;&lt; Назад
      </div>
      <button v-if="!mobile" class="button__order">ЗАКАЗАТЬ</button>
      <button
        v-if="mobile"
        class="button__mobile yellow"
        style="margin-top: 40px"
      >
        ЗАКАЗАТЬ
      </button>
    </div>
  </form>
</template>

<script>
// Store
import { mapState, mapActions } from "vuex";
// Components Form
import FormInput from "@/components/form/form-input";
// Mixins
import computedsOrder from "@/mixins/order/computeds-order";
import methodsNumber from "@/mixins/methods-number";

export default {
  name: "OrderLead",
  props: {
    orderId: Number,
    mobile: Boolean,
    show: {
      type: Boolean,
      default: false
    }
  },
  components: { FormInput },
  mixins: [methodsNumber, computedsOrder],
  data() {
    return {
      phone: null,
      email: null,
      openOrder: false
    };
  },
  computed: {
    // Store
    ...mapState({
      savedOrders: (state) => state.savedOrders
    })
  },
  methods: {
    // Store
    ...mapActions({
      deleteOrders: "deleteOrdersState",
      // deleteAllOrders: "deleteAllOrdersState",
      editOrders: "editOrdersState",
      saveOrders: "saveOrdersState",
      changeOrdersCount: "changeOrdersStateCount",
      saveOrdersDataState: "saveOrdersDataState"
    }),
    // Изменение количества машин
    changeOrderCount(value, count, index) {
      if (count >= 1) {
        if (value === 1 && count > 1) {
          this.changeOrdersCount({
            id: index,
            count: count - 1
          });
        }
        if (value === 2) {
          this.changeOrdersCount({
            id: index,
            count: count + 1
          });
        }
      }
    },
    resetForm() {
      this.$emit("resetForm");
    },
    // Удаление заказа из сохранённых
    removeOrder(id) {
      this.deleteOrders(id);
      this.resetForm();
      if (!this.savedOrders.length) {
        this.$emit("mobileOrderHandler", false);
      }
    },
    // Редактирование заказа
    editOrder(id) {
      this.openOrder = !this.openOrder;
      if (this.openOrder) {
        this.editOrders(id);
        this.$emit("editOrder", id);
      } else {
        this.resetForm();
      }
      this.$emit("mobileOrderHandler", false);
    },
    // Возвращаемся назад к странице создания заказа
    backToPage() {
      this.resetForm();
      this.$emit("mobileOrderHandler", false);
    },
    // Формируем данные для отправки в БИТРИКС
    addLead() {
      if (this.phone && this.email) {
        this.saveOrdersDataState({
          email: this.email,
          phone: this.phone
        });
        localStorage.setItem("phoneClient", this.phone);
        localStorage.setItem("emailClient", this.email);
        this.$emit("update:show", true);
        this.resetForm();
      } else {
        alert("Заполните обязательные поля!");
      }
    }
  },
  mounted() {
    const phone = localStorage.getItem("phoneClient");
    const email = localStorage.getItem("emailClient");
    this.phone = phone ? phone : null;
    this.email = email ? email : null;
  }
};
</script>

<style lang="scss">
.cost__mobile {
  bottom: 110px;
  position: fixed;
  right: 20px;
  z-index: 99;
}
</style>
