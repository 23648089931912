<template>
  <div class="form-input">
    <label
      v-if="label"
      :class="{ invalid: isInvalid }"
      :style="`width: ${width}px`"
      :for="id"
      >{{ label }} <small v-if="required" class="required">*</small></label
    >
    <input
      :class="{ invalid: isInvalid }"
      :id="id"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :value="trimValue"
      :style="styles"
      :readonly="readonly"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <small v-if="isInvalid && invalidText" class="invalidText">
      {{ invalidText }}
    </small>
    <img
      v-if="icon"
      class="icon"
      :src="require(`@/assets/images/${icon}.svg`)"
      alt="Icon"
    />
  </div>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: ""
    },
    trimData: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    styles: {
      type: [String, Object]
    },
    width: String,
    invalidText: String,
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: "input-" + Math.random(),
      name: "name-" + Math.random()
    };
  },
  computed: {
    trimValue() {
      if (this.modelValue && this.trimData) {
        return this.modelValue.toString().trim();
      } else {
        return this.modelValue;
      }
    }
  }
};
</script>

<style lang="scss">
.form-input {
  position: relative;

  label {
    background-color: $color-white;
    font-size: 12px;
    font-weight: 500;
    left: 10px;
    padding: 0 5px;
    position: absolute;
    top: -7px;
    z-index: 1;

    &.invalid {
      color: $color-red;
    }

    .required {
      color: $color-red;
      font-size: 12px;
      font-weight: 500;
    }
  }

  input {
    background-color: $color-white !important;
    border: 1px solid $color-basic;
    border-radius: 8px;
    color: $color-basic;
    height: 32px;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    outline: none;
    overflow: hidden;
    padding: 16px 34px 16px 16px;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    width: 100%;

    &:active {
      outline: none;
    }

    &:focus {
      border: 1px solid $color-basic;
      outline: none;
      background: $color-white !important;
    }

    &.invalid {
      border: 1px solid $color-red;
      color: $color-red;

      &::placeholder {
        color: $color-red !important;
      }
    }
  }

  img {
    height: 24px;
    right: 9px;
    position: absolute;
    top: 4px;
    width: 24px;
  }

  input[type="date"],
  input[type="time"] {
    height: 32px !important;
    padding: 4px 8px !important;
    width: 176px !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: $color-white !important;
  color: $color-basic !important;
}

::placeholder {
  color: $color-grey;
}

:focus::placeholder {
  color: transparent;
}

.input__custom--icon {
  position: absolute;
  top: 3px;
  right: 23px;
  z-index: 3;
}

.invalidText {
  align-items: center;
  display: flex;
  color: $color-red;
  justify-content: flex-start;
  margin: 2px 0 0 3px;
}
</style>
