<template>
  <draggable
    :list="routes"
    handle=".order__route--move"
    @change="$emit(`chageRouteTurn`)"
  >
    <div v-for="(route, idx) in routes" :key="idx" class="order__route">
      <img
        v-if="routes.length > 1"
        class="order__route--move"
        src="@/assets/images/move.svg"
        alt="Передвинуть"
        title="Передвинуть адрес"
      />
      <form-input
        v-model="route.address"
        :label="route.name"
        :trimData="false"
        :placeholder="route.title"
        styles="margin-bottom: 15px"
        icon="pin"
        @input="addHelpToRoutes($event, idx)"
        ref="orderRoute"
      ></form-input>
      <img
        v-if="routes.length > 1"
        @click="$emit(`removeOrderRoute`, idx)"
        class="order__route--delete"
        src="@/assets/images/delete.svg"
        alt="Удалить"
        title="Удалить адрес"
      />
      <ul v-if="dadataTemplate[idx]" class="order__dadata">
        <li
          v-for="(item, index) in dadataTemplate[idx]"
          :key="index"
          @click="(route.address = item.value), (dadataTemplate[idx] = null)"
        >
          {{ item.value }}
        </li>
      </ul>
    </div>
  </draggable>
</template>

<script>
// Store
import { mapState } from "vuex";
// Draggable
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
// Components Form
import FormInput from "@/components/form/form-input";

export default defineComponent({
  name: "OrderRoutes",
  props: { routes: Array },
  components: { draggable: VueDraggableNext, FormInput },
  emits: ["chageRouteTurn", "removeOrderRoute"],
  data() {
    return {
      zapros: {
        // DaData только Москва и МО
        locations: [{ kladr_id: "77" }, { kladr_id: "50" }]
      },
      dadataTemplate: []
    };
  },
  computed: {
    // Store
    ...mapState({
      datataApiKey: (state) => state.datataApiKey
    })
  },
  methods: {
    addHelpToRoutes(event, index) {
      this.dadataTemplate = [];
      if (event.target.value) {
        this.zapros.query = event.target.value;
        this.axios
          .post(
            "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
            JSON.stringify(this.zapros),
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Token " + this.datataApiKey
              }
            }
          )
          .then((response) => {
            this.dadataTemplate[index] = response.data.suggestions;
          })
          .catch((e) => {
            alert("Ошибка отправки запроса в базу адресов! " + e.message);
          });
      } else {
        this.dadataTemplate[index] = null;
      }
    },
    // Клик не на DaData
    orderDaData(e) {
      let el = this.$refs.orderRoute;
      let target = e.target;
      if (el !== target && !el.includes(target)) {
        this.dadataTemplate = [];
      }
    }
  },
  created() {
    document.addEventListener("click", this.orderDaData);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.orderDaData);
  }
});
</script>

<style lang="scss">
.order__dadata {
  background-color: $color-white;
  border: 1px solid $color-basic;
  border-radius: 8px;
  color: $color-basic;
  list-style: none;
  position: absolute;
  top: 36px;
  width: 100%;
  z-index: 100;

  li {
    cursor: pointer;
    font-size: 12px;
    padding: 5px;
    text-align: left;

    &:hover {
      background-color: $color-grey-light;
    }
  }
}
</style>
