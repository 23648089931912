<template>
  <div class="orders__paid">
    <img
      v-if="!mobile"
      @click="$emit(`closePayd`, false)"
      class="orders__paid--close"
      src="@/assets/images/close.svg"
      alt="Закрыть"
      title="Закрыть"
    />
    <div class="order">
      <div v-if="!mobile" class="order__title">
        Выберите удобный способ оплаты заказа
      </div>
      <div v-else class="order__title--mobile">
        Выберите удобный способ<br />оплаты заказа
      </div>
      <div class="order__form">
        <div
          class="order__form--block"
          :style="mobile ? `display: block; width: 100%` : ``"
        >
          <div class="order__form--date" :style="mobile ? `width: 100%` : ``">
            <div
              @click="changePaid(1)"
              :class="
                orderPaydType[0] === 1
                  ? `paid__card first active`
                  : `paid__card first`
              "
            >
              <p>Банковская карта</p>
              <svg
                width="77"
                height="77"
                viewBox="0 0 77 77"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_17864_100459)">
                  <path
                    d="M64.168 12.833H12.8346C9.28943 12.833 6.45005 15.7045 6.45005 19.2497L6.41797 57.7497C6.41797 61.2949 9.28943 64.1663 12.8346 64.1663H64.168C67.7132 64.1663 70.5846 61.2949 70.5846 57.7497V19.2497C70.5846 15.7045 67.7132 12.833 64.168 12.833ZM64.168 57.7497H12.8346V38.4997H64.168V57.7497ZM64.168 25.6663H12.8346V19.2497H64.168V25.6663Z"
                    fill="#58595B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17864_100459">
                    <rect width="77" height="77" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="order__form--date" :style="mobile ? `width: 100%` : ``">
            <div
              @click="changePaid(2)"
              :class="
                orderPaydType[0] === 2
                  ? `paid__card second active`
                  : `paid__card second`
              "
            >
              <p :style="!mobile ? `margin-bottom: 12px` : ``">
                Счет на оплату
              </p>
              <svg
                width="58"
                height="58"
                viewBox="0 0 58 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_18160_5433)">
                  <path
                    d="M31.867 16.9713V0.666016H19.1851C17.7436 0.666016 16.3612 1.23864 15.3419 2.25792C14.3226 3.2772 13.75 4.65963 13.75 6.10111V45.9585C13.75 47.4 14.3226 48.7824 15.3419 49.8017C16.3612 50.821 17.7436 51.3936 19.1851 51.3936H48.1723C49.6138 51.3936 50.9962 50.821 52.0155 49.8017C53.0347 48.7824 53.6074 47.4 53.6074 45.9585V22.4064H37.3021C35.8606 22.4064 34.4782 21.8338 33.4589 20.8145C32.4396 19.7952 31.867 18.4128 31.867 16.9713Z"
                    fill="#58595B"
                  />
                  <path
                    d="M35.4844 16.9698V1.57031L52.6955 18.7815H37.2961C36.8156 18.7815 36.3548 18.5906 36.015 18.2508C35.6752 17.9111 35.4844 17.4502 35.4844 16.9698Z"
                    fill="#58595B"
                  />
                  <path
                    d="M6.5 11.5355C6.5 10.5745 6.88175 9.6529 7.56127 8.97338C8.24079 8.29386 9.16241 7.91211 10.1234 7.91211V45.9578C10.1234 48.3602 11.0778 50.6643 12.7766 52.3631C14.4754 54.0619 16.7794 55.0163 19.1819 55.0163H46.3574C46.3574 55.9773 45.9756 56.8989 45.2961 57.5784C44.6166 58.2579 43.695 58.6397 42.734 58.6397H19.3558C15.9462 58.6397 12.6763 57.2852 10.2654 54.8743C7.85445 52.4634 6.5 49.1934 6.5 45.7839V11.5355Z"
                    fill="#58595B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_18160_5433">
                    <rect
                      width="56.6667"
                      height="56.6667"
                      fill="white"
                      transform="translate(0.832031 0.666992)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div v-if="mobile && sumLead" class="cost__mobile">
          <div class="order__form--cost">
            Итого:
            <h2>{{ sumLead }}</h2>
            &#8381;
          </div>
        </div>
      </div>
      <div
        :class="orderPaydType.length ? `save__button` : `save__button short`"
      >
        <div
          v-if="mobile"
          @click="$emit(`closePayd`, false)"
          class="button__back"
        >
          &lt;&lt; Назад
        </div>
        <button
          v-if="orderPaydType[0] === 1"
          @click="openCardPayd"
          :class="mobile ? `button__mobile yellow` : `button__order`"
          :style="mobile ? `margin-top: 40px` : ``"
        >
          ОПЛАТИТЬ
        </button>
        <button
          v-if="orderPaydType[0] === 2"
          @click="createPDF"
          :class="mobile ? `button__mobile yellow` : `button__order`"
          :style="mobile ? `margin-top: 40px` : ``"
        >
          СКАЧАТЬ
        </button>
        <button
          v-if="orderPaydType.length === 0 && mobile"
          class="button__mobile empty"
          disabled
        >
          ВЫБЕРИТЕ ТИП ОПЛАТЫ
        </button>
      </div>
    </div>
  </div>
  <vue3-html2pdf
    v-if="savedOrders.length"
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    filename="payment"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width=""
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <print-score></print-score>
    </template>
  </vue3-html2pdf>
</template>

<script>
// Store
import { mapState, mapActions } from "vuex";
// PDF
import Vue3Html2pdf from "vue3-html2pdf";
// Print
import PrintScore from "@/components/main/print-score";
// Mixins
import computedsOrder from "@/mixins/order/computeds-order";
import methodsNumber from "@/mixins/methods-number";

export default {
  name: "OrderPaid",
  props: { mobile: Boolean },
  emits: ["closePayd", "successOrder"],
  components: { Vue3Html2pdf, PrintScore },
  mixins: [computedsOrder, methodsNumber],
  data() {
    return {
      orderPaydType: [],
      orderPaydTypeName: [
        { id: 1, name: "Банковская карта" }, // Оплата банковской картой
        { id: 2, name: "Выставлен счёт" } // Оплата авансовым счётом
      ],
      descPaydCard: "Заказ траспортного средства",
      sendPaydCardLink: "https://youkassa.tkglp.ru/create"
    };
  },
  computed: {
    // Store
    ...mapState({
      savedOrders: (state) => state.savedOrders,
      savedOrdersData: (state) => state.savedOrdersData,
      addLeadMethods: (state) => state.addLeadMethods,
      userBitrixId: (state) => state.userBitrixId,
      userEmail: (state) => state.userEmail,
      paymentId: (state) => state.paymentId
    })
  },
  methods: {
    // Store
    ...mapActions({
      deleteAllOrders: "deleteAllOrdersState",
      saveOrdersDataState: "saveOrdersDataState",
      changeOrdersPaymentId: "changeOrdersPaymentId"
    }),
    // Формирование полей для отправки в Bitrix
    addBitrixData() {
      const fields = {
          TITLE: `Заказ транспорта ${this.savedOrdersData.phone}`,
          STATUS_ID: "NEW",
          OPENED: "Y",
          ASSIGNED_BY_ID: this.userBitrixId,
          CURRENCY_ID: "RUB",
          OPPORTUNITY: this.sumLead,
          SOURCE_DESCRIPTION: this.createCommentsBitrix,
          PHONE: [{ VALUE: this.savedOrdersData.phone, VALUE_TYPE: "WORK" }],
          EMAIL: [{ VALUE: this.savedOrdersData.email, VALUE_TYPE: "WORK" }]
        },
        params = { REGISTER_SONET_EVENT: "Y" };
      // Сохранение данных в STORE
      this.saveOrdersDataState({
        ...this.savedOrdersData,
        ...{ fields, params, message: this.createTemplateEmail }
      });
    },
    // Выбор варианта оплаты
    changePaid(id) {
      this.orderPaydType = [];
      this.orderPaydType.push(id);
      const namePayd = this.orderPaydTypeName.find((item) => item.id === id);
      this.saveOrdersDataState({
        ...this.savedOrdersData,
        ...{ namePayd: namePayd.name }
      });
      this.addBitrixData();
    },
    // Отправка данных на электронную почту
    sendToEmail(data) {
      this.axios
        .post("https://mail.tkglp.ru/mail", data)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          alert("Ошибка отправки по почте! " + e.message);
        });
    },
    // Отправка данных в БИТРИКС
    addLeadHandler(data) {
      this.axios
        .post(this.addLeadMethods, data)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          alert("Ошибка сохранения! " + e.message);
        });
    },
    async sendOrderToSend() {
      // Отправка Лида в БИТРИКС
      await this.addLeadHandler({
        fields: this.savedOrdersData.fields,
        params: this.savedOrdersData.params
      });
      // Отправка почтового сообщения клиенту
      await this.sendToEmail({
        email: this.savedOrdersData.email,
        subject: "Подтверждение заказа tkglp.ru",
        message: this.savedOrdersData.message
      });
      // Отправка почтового сообщения менеджеру
      await this.sendToEmail({
        email: this.userEmail,
        subject: `Заказ транспорта ${this.savedOrdersData.phone}`,
        message: this.savedOrdersData.message
      });
    },
    // Функции выполнения после сохранения
    getSavedFunction() {
      this.$emit("closePayd", false);
      this.$emit("successOrder", true);
      this.deleteAllOrders();
      this.saveOrdersDataState({});
    },
    getSavedNoSuccess() {
      this.$emit("closePayd", false);
      this.deleteAllOrders();
      this.saveOrdersDataState({});
    },
    // Отправка данных для оплаты картой
    sendPaydCardToServer(data) {
      this.axios
        .post(this.sendPaydCardLink, data)
        .then((response) => {
          if (response.data.error) {
            alert("Ошибка формирования страницы оплаты картой!");
          } else {
            // Сохраняем ID платежа в VUEX
            this.changeOrdersPaymentId(response.data.paymentId);
            this.saveOrdersDataState({
              ...this.savedOrdersData,
              ...{ paymentId: response.data.paymentId }
            });
            this.addBitrixData();
            // Отправляем на страницу оплаты
            window.open(response.data.confirmationUrl, "_blank");
            this.sendOrderToSend().then(() => {
              this.getSavedFunction();
            });
          }
        })
        .catch((e) => {
          alert("Ошибка формирования страницы оплаты картой! " + e.message);
        });
    },
    // Открытие ссылки на оплату картой
    openCardPayd() {
      const data = {
        amount: this.sumLeadWithout + ".00",
        description: this.descPaydCard,
        // returnUrl: "https://lead.tkglp.ru/"
        returnUrl: window.location.href
      };
      this.sendPaydCardToServer(JSON.stringify(data));
    },
    // Создание документа PDF
    createPDF() {
      this.$refs.html2Pdf.generatePdf();
      this.sendOrderToSend().then(() => {
        this.getSavedFunction();
      });
    },
    // Закрыть оплату
    close() {
      this.$emit("closePayd", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.orders__paid {
  padding: 3rem 0 !important;
  position: relative;

  .order__title {
    margin-bottom: 1rem;
  }

  &--close {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 20px;

    &:hover {
      opacity: 0.7;
    }
  }
}
.paid__card {
  border: 1px solid $color-basic;
  border-radius: 5px;
  color: $color-basic;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  height: 188px;
  // margin: 2rem auto;
  padding: 20px 30px;
  width: 188px;

  &.first {
    margin: 2rem 0 2rem auto;
  }
  &.second {
    margin: 2rem auto 2rem 0;
  }

  @media (max-width: 767px) {
    @include flex-content-between;
    font-size: 14px;
    height: 100px;
    margin: 1rem auto;
    padding: 10px 20px;
    width: 100%;

    &.second {
      padding: 10px 30px 10px 20px;
    }
  }

  &.active,
  &:hover {
    border: 1px solid $color-green;
    color: $color-green;

    svg {
      path {
        fill: $color-green;
      }
    }
  }

  p {
    text-transform: uppercase;
  }

  svg {
    margin-top: 15px;

    @media (max-width: 767px) {
      margin: 0;
    }
  }
}
.order__title--mobile {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}
</style>
