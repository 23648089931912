<template>
  <div class="order__gallery" v-touch:swipe="onSwipeItem">
    <img
      v-if="thisCarType.id === typeCar && thisCarType.image"
      class="type__image"
      :src="require(`@/assets/images/${thisCarType.image}`)"
      :alt="thisCarType.name"
    />
    <div class="car__change left" @click="changeActive(1)"></div>
    <div v-for="item in cars" :key="item.id">
      <div
        v-if="tonnage === item.id"
        :style="{
          'background-image': `url(${require(`@/assets/images/cars/${item.image}`)})`,
          'background-position': `center`,
          'background-repeat': `no-repeat`,
          'background-size': `100%`
        }"
        class="car__image"
      ></div>
    </div>
    <div class="car__change right" @click="changeActive(2)"></div>
  </div>
  <div class="order__name">
    <div
      v-for="item in cars"
      :key="item.id"
      :class="tonnage === item.id ? `active` : ``"
      @click="$emit(`changeActiveCar`, item.id)"
    >
      {{ mobile ? item.short : item.name }}
    </div>
  </div>
  <div class="order__cars">
    <div
      v-for="item in type"
      :key="item.id"
      :class="typeCar === item.id ? `active` : ``"
      @click="$emit(`changeActiveType`, item.id)"
    >
      {{ mobile ? item.short : item.name }}
    </div>
  </div>
</template>

<script>
// Mixins
import dataCars from "@/mixins/data-cars";

export default {
  name: "OrderCars",
  props: { tonnage: Number, typeCar: Number, mobile: Boolean },
  mixins: [dataCars],
  emits: ["changeActiveCar", "changeActiveType"],
  data() {
    return {
      carImg: ""
    };
  },
  computed: {
    thisCarType() {
      return this.type.find((item) => item.id === this.typeCar);
    }
  },
  methods: {
    changeActive(value) {
      const temp = this.tonnage;
      if (value === 1) {
        let less = 1;
        this.tonnage > 1 ? (less = temp - 1) : (less = this.cars.length);
        this.$emit(`changeActiveCar`, less);
      }
      if (value === 2) {
        let add = 1;
        this.tonnage < this.cars.length ? (add = temp + 1) : 1;
        this.$emit(`changeActiveCar`, add);
      }
    },
    onSwipeItem(direction) {
      if (direction === "left") {
        this.changeActive(2);
      } else {
        this.changeActive(1);
      }
    }
  }
};
</script>
