export default {
  methods: {
    // Правильная дата
    selectDate() {
      this.order.createDate = new Date(this.order.date).toLocaleDateString();
    },
    // Правильное время
    selectTime() {
      let hour = this.order.time.hours,
        minute = this.order.time.minutes;
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minute < 10) {
        minute = `0${minute}`;
      }
      this.order.createTime = `${hour}:${minute}`;
    },
    // Смена тонажа
    changeActiveCar(id) {
      this.activeCar = id;
    },
    // Смена типа кузова
    changeActiveType(id) {
      this.activeType = id;
    }
  }
};
