export default {
  data() {
    return {
      cars: [
        {
          id: 1,
          name: "До 800 кг",
          short: "0.8т",
          image: "car1.png"
        },
        {
          id: 2,
          name: "До 1 т",
          short: "1т",
          image: "car2.png"
        },
        {
          id: 3,
          name: "До 1.5 т",
          short: "1.5т",
          image: "car3.png"
        },
        {
          id: 4,
          name: "До 3 т",
          short: "3т",
          image: "car4.png"
        },
        {
          id: 5,
          name: "До 5 т",
          short: "5т",
          image: "car5.png"
        },
        {
          id: 6,
          name: "До 10 т",
          short: "10т",
          image: "car6.png"
        },
        {
          id: 7,
          name: "До 20 т",
          short: "20т",
          image: "car7.png"
        }
      ],
      type: [
        {
          id: 1,
          name: "Тент",
          short: "Тент",
          image: ""
        },
        {
          id: 2,
          name: "Промтоварная",
          short: "Пром",
          image: ""
        },
        {
          id: 3,
          name: "Изотерма",
          short: "Терм",
          image: "isoterma.svg"
        },
        {
          id: 4,
          name: "Рефрижератор",
          short: "Реф",
          image: "ref.svg"
        }
      ]
    };
  }
};
