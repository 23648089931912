export default {
  methods: {
    // Вывод стоимости с разделителями через 3 цифры
    roundingNumber(value, notTail = 0) {
      // if (!value || value === "" || value == "0") {
      if (!value || value === "") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else {
            tail = "." + arr[1];
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    },
    // Автосумма всех значений в массиве с разделителем
    sumColls(value) {
      if (value) {
        return this.roundingNumber(
          (+value.reduce((a, b) => a + b, 0).toFixed(2)).toString(),
          1
        );
      } else {
        return "0";
      }
    },
    // Автосумма всех значений в массиве без разделителем
    sumCollsWithout(value) {
      if (value) {
        return +value.reduce((a, b) => a + b, 0);
      } else {
        return "0";
      }
    }
  }
};
