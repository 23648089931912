<template>
  <div class="lead__form">
    <div class="lead__form--block">
      <form v-if="!success" class="order__create">
        <div v-if="!mobileOrder" class="order">
          <!-- <div v-if="mobile" class="order__title">
            Рассчитай стоимость и сделай заказ
          </div> -->
          <order-cars
            :tonnage="activeCar"
            :typeCar="activeType"
            :mobile="mobile"
            @changeActiveCar="changeActiveCar"
            @changeActiveType="changeActiveType"
          ></order-cars>
          <div class="order__form">
            <div class="order__form--block">
              <div class="order__form--date">
                <label>Дата подачи</label>
                <DatePicker
                  id="pickerDate"
                  v-model="order.date"
                  :format="formatedView"
                  @update:modelValue="selectDate"
                  :enableTimePicker="false"
                  monthNameFormat="long"
                  locale="ru"
                  autoApply
                  utc
                  placeholder="--:--:--"
                  :minDate="new Date()"
                  :transitions="false"
                >
                  <template #calendar-header="{ index, day }">
                    <div
                      :class="index === 5 || index === 6 ? 'color__red' : ''"
                    >
                      {{ day }}
                    </div>
                  </template>
                </DatePicker>
                <img
                  v-if="!order.date"
                  for="pickerDate"
                  src="@/assets/images/calendar.svg"
                  alt="Календарь"
                />
              </div>
              <div class="order__form--date">
                <label>Время подачи</label>
                <DatePicker
                  v-model="order.time"
                  @update:modelValue="selectTime"
                  id="pickerTime"
                  timePicker
                  selectText="Выбрать"
                  cancelText=""
                  placeholder="--:--"
                  :transitions="false"
                />
                <img
                  v-if="!order.time"
                  for="pickerTime"
                  src="@/assets/images/time.svg"
                  alt="Часы"
                />
              </div>
            </div>
            <order-routes
              :routes="order.routes"
              @chageRouteTurn="chageRouteTurn"
              @removeOrderRoute="removeOrderRoute"
            ></order-routes>
            <div
              class="order__form--block"
              :style="mobile ? `margin: 0 -5px` : ``"
            >
              <div class="order__form--add" @click="addOrderRoute">
                <img src="@/assets/images/plus-btn.svg" alt="Добавить" />
                <p v-if="mobile">Добавить точку</p>
                <p v-else>Добавить точку маршрута</p>
              </div>
              <div class="order__form--add">
                <span>КМ за МКАД</span>
                <img
                  @click="
                    +order.km > 0
                      ? (order.km = +order.km - 1)
                      : (order.km = +order.km)
                  "
                  src="@/assets/images/minus.svg"
                  alt="Минус"
                />
                <form-input
                  v-model="order.km"
                  placeholder="0"
                  styles="border: none; margin: 0; padding: 0; text-align: center; width: 30px"
                ></form-input>
                <!-- <span style="margin: 0; width: 30px">{{ order.km }}</span> -->
                <img
                  @click="
                    order.km ? (order.km = +order.km + 1) : (order.km = 1)
                  "
                  src="@/assets/images/plus.svg"
                  alt="Плюс"
                />
              </div>
            </div>
            <form-textarea
              v-model="order.comment"
              label="Коментарий к рейсу"
              styles="height: 80px"
              placeholder="Если есть детали по рейсу напишите о них "
            ></form-textarea>
          </div>
          <order-tarif
            :tonnage="activeCar"
            :typeCar="activeType"
            :mobile="mobile"
            :costKm="order.km"
            @getCostTarif="getCostTarif"
          ></order-tarif>
        </div>
        <div class="save__button">
          <div v-if="!mobile">
            <button type="button" v-if="orderId !== null" @click="saveOrder">
              СОХРАНИТЬ<br />ИЗМЕНЕНИЯ
            </button>
            <button type="button" v-else @click="saveOrder">
              СОХРАНИТЬ<br />И ДОБАВИТЬ К РАССЧЁТУ
            </button>
          </div>
          <div v-else>
            <button
              v-if="!savedOrders.length"
              type="button"
              class="button__mobile"
              style="margin: 30px auto 0 auto"
              @click="saveOrder"
            >
              ДОБАВИТЬ К ЗАКАЗУ <span>+</span>
            </button>
            <div v-else class="save__button--mobile">
              <button
                v-if="orderId !== null"
                type="button"
                class="button__mobile"
                @click="saveOrder"
              >
                СОХРАНИТЬ
              </button>
              <button
                v-else-if="!isPayd"
                type="button"
                class="button__mobile"
                @click="saveOrder"
              >
                ДОБАВИТЬ <span>+</span>
              </button>
              <button
                v-if="!isPayd"
                type="button"
                class="button__mobile yellow"
                @click.prevent="mobileOrder = true"
              >
                К ЗАКАЗУ
                <span class="button__mobile--badge">{{
                  savedOrders.length
                }}</span>
              </button>
            </div>
          </div>
          <!-- <button
            v-if="orderId !== null"
            class="button__reset"
            @click="clearForm"
          >
            ОТМЕНИТЬ<br />ИЗМЕНЕНИЯ
          </button> -->
        </div>
      </form>
      <order-success v-else @successOrder="successOrderHandler"></order-success>
      <order-lead
        v-if="mobileOrder && savedOrders.length"
        v-model:show="isPayd"
        :mobile="mobile"
        :orderId="orderId"
        @editOrder="editOrder"
        @mobileOrderHandler="mobileOrderHandler"
        @resetForm="resetForm"
      ></order-lead>
      <order-paid
        v-if="mobileOrder && isPayd && savedOrders.length"
        :mobile="mobile"
        @closePayd="closePaydHandler"
        @successOrder="successOrderHandler"
      ></order-paid>
    </div>
    <div v-if="!mobile" class="lead__form--block">
      <div v-if="!savedOrders.length" class="lead__form--empty">
        <div>
          <h1>Рассчитайте стоимость или сделайте заказ</h1>
          <h4>
            Эта форма поможет Вам собрать заказ из разных видов ТС и
            спрогнозировать бюджет на перевозку
          </h4>
        </div>
      </div>
      <order-lead
        v-else
        v-model:show="isPayd"
        :mobile="mobile"
        :orderId="orderId"
        @editOrder="editOrder"
        @resetForm="resetForm"
      ></order-lead>
      <order-paid
        v-if="isPayd"
        :mobile="mobile"
        @closePayd="closePaydHandler"
        @successOrder="successOrderHandler"
      ></order-paid>
    </div>
  </div>
</template>

<script>
// Store
import { mapState, mapActions } from "vuex";
// Components Form
import FormInput from "@/components/form/form-input";
import FormTextarea from "@/components/form/form-textarea";
// Components Orders
import OrderLead from "@/components/main/order-lead";
import OrderPaid from "@/components/main/order-paid";
import OrderSuccess from "@/components/main/order-success";
import OrderCars from "@/components/main/order-cars";
import OrderRoutes from "@/components/main/order-routes";
import OrderTarif from "@/components/main/order-tarif";
// Mixins
import checkMobile from "./mixins/check-mobile";
import presetRanges from "@/mixins/datepicker-presets";
import dataCars from "@/mixins/data-cars";
import methodsRouters from "@/mixins/order/methods-routers";
import methodsOrder from "@/mixins/order/methods-order";
import methodsForm from "@/mixins/order/methods-form";

export default {
  name: "App",
  components: {
    FormInput,
    FormTextarea,
    OrderLead,
    OrderPaid,
    OrderSuccess,
    OrderCars,
    OrderRoutes,
    OrderTarif
  },
  mixins: [
    checkMobile,
    presetRanges,
    dataCars,
    methodsRouters,
    methodsOrder,
    methodsForm
  ],
  data() {
    return {
      order: {},
      orderId: null,
      activeCar: 1,
      activeType: 2,
      costTarif: 0,
      mobileOrder: false,
      isPayd: false,
      success: false
    };
  },
  computed: {
    // Store
    ...mapState({
      savedOrders: (state) => state.savedOrders,
      currentOrder: (state) => state.currentOrder,
      paymentId: (state) => state.paymentId
    }),
    selectedCar() {
      return this.cars.find((item) => item.id === this.activeCar);
    },
    selectedType() {
      return this.type.find((item) => item.id === this.activeType);
    }
  },
  methods: {
    // Store
    ...mapActions({
      addOrders: "addOrdersState",
      saveOrders: "saveOrdersState",
      changeOrdersPaymentId: "changeOrdersPaymentId"
    }),
    closePaydHandler(value) {
      this.isPayd = value;
    },
    successOrderHandler(value) {
      this.success = value;
      this.mobileOrder = value;
      this.changeOrdersPaymentId("");
    },
    mobileOrderHandler(value) {
      this.mobileOrder = value;
    }
  },
  mounted() {
    this.addStartRoute();
    // this.order.km = 0;
    this.paymentId ? (this.success = true) : (this.success = false);
  }
};
</script>
