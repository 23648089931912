<template>
  <div class="orders__success">
    <img
      @click="$emit(`successOrder`, false)"
      class="orders__success--close"
      src="@/assets/images/close.svg"
      alt="Закрыть"
      title="Закрыть"
    />
    <Lottie
      :options="optionsCar"
      class="orders__success--car"
      :width="210"
      :height="210"
    />
    <h1>Заказ успешно создан</h1>
    <div class="orders__success--loading">
      <Lottie :options="optionsCircle" :width="120" :height="120" />
    </div>
    <p>
      Мы уже начали подбирать исполнителей в скором времени мы с вами свяжемся
    </p>
    <p>Спасибо за заказ!</p>
    <div class="save__success">
      <button @click="$emit(`successOrder`, false)" class="button__order">
        ОК
      </button>
    </div>
  </div>
</template>

<script>
// Animation
import Lottie from "vue-lottie";
import checkCircle from "@/assets/animations/check-circle.js";
import loadingCar from "@/assets/animations/loading-car.js";

export default {
  name: "OrderSuccess",
  emits: ["successOrder"],
  components: { Lottie },
  data() {
    return {
      optionsCircle: { animationData: checkCircle, loop: false },
      optionsCar: { animationData: loadingCar },
      animationSpeed: 1
    };
  }
};
</script>

<style lang="scss" scoped>
.orders__success {
  text-align: center;

  @media (max-width: 767px) {
    height: 100vh !important;
    .save__success {
      .button__order {
        font-size: 1rem;
      }
    }
  }

  &--close {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 20px;

    &:hover {
      opacity: 0.7;
    }
  }

  &--car {
    display: block;
    margin: 0 auto;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &--loading {
    @include flex;
    margin: 20px auto;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    margin: 50px auto;
    max-width: 400px;

    @media (max-width: 767px) {
      font-size: 1rem;
      margin: 1.5rem auto;
    }
  }
}
</style>
