<template>
  <div class="order__tarif">
    <div class="order__tarif--cost">
      <span v-if="!mobile">Стоимость:</span>
      <h2>{{ roundingNumber(costTarif, 1) }}</h2>
      &#8381;
    </div>
    <div class="order__tarif--data">
      <div>
        <span v-if="!mobile">тариф </span>за {{ tarifData.time }} час |
        {{ tarifData.extra }}&#8381; за доп. час<span v-if="!mobile">
          |&nbsp;</span
        >
      </div>
      <div>{{ tarifData.mkad }}&#8381; - км. за МКАД</div>
    </div>
    <div v-if="!mobile">
      <a
        :href="tarifsLink"
        title="Смотреть базовые тарифы"
        target="_blank"
        class="order__tarif--link"
        >Смотреть базовые тарифы</a
      >
    </div>
  </div>
</template>

<script>
// Store
import { mapState } from "vuex";
// Mixins
import methodsNumber from "@/mixins/methods-number";
import dataTarif from "@/mixins/data-tarif";

export default {
  name: "OrderTarif",
  props: {
    tonnage: Number,
    typeCar: Number,
    mobile: Boolean,
    costKm: { type: [Number, String] }
  },
  mixins: [methodsNumber, dataTarif],
  computed: {
    // Store
    ...mapState({
      tarifsLink: (state) => state.tarifsLink
    }),
    tarifData() {
      return this.tarif.find(
        (item) => item.car === this.tonnage && item.type === this.typeCar
      );
    },
    costTarif() {
      if (this.costKm) {
        const data =
          this.tarifData.cost + this.tarifData.mkad * (this.costKm * 2);
        this.$emit("getCostTarif", data);
        return data;
      } else {
        this.$emit("getCostTarif", this.tarifData.cost);
        return this.tarifData.cost;
      }
    }
  }
};
</script>
