import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
// Для мобильных
import Vue3TouchEvents from "vue3-touch-events";
// Для инпутов
import Maska from "maska";
// Datepicker
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

axios.defaults.baseURL =
  "https://glpgruzoperevozki.bitrix24.ru/rest/166/m61j4tf7t3nhk3zc/";

const app = createApp(App);
// Datepicker
app.component("DatePicker", Datepicker);

app.use(Vue3TouchEvents);
app.use(store);
app.use(VueAxios, axios);
app.use(Maska);
app.mount("#app");
