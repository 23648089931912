// DatePicker presetRanges
import { addDays } from "date-fns";

export default {
  data() {
    return {
      presetRanges: [
        {
          label: "Сегодня",
          date: new Date()
        },
        {
          label: "Завтра",
          date: addDays(new Date(), 1)
        }
      ],
      formatedView: "dd.MM.yyyy"
    };
  }
};
