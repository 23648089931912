import { createStore } from "vuex";
const orders = localStorage.getItem("savedLeadOrders")
    ? JSON.parse(localStorage.getItem("savedLeadOrders"))
    : [],
  paymentId = localStorage.getItem("paymentId")
    ? localStorage.getItem("paymentId")
    : null;

const state = () => ({
  savedOrders: orders ? orders : [],
  savedOrdersData: {},
  currentOrder: null,
  //tarifsLink: "https://tkglp.ru/tarify-na-transport/#rec480978360",
  tarifsLink: "https://tkglp.ru/tarify-na-transport/",
  addLeadMethods: "crm.lead.add.json",
  userBitrixId: 82, // Вадим Важенин
  userEmail: "p3@tkglp.ru",
  datataApiKey: "886c96c3d968d2f3d0127810c737b6e5604ca64a",
  paymentId
});

const mutations = {
  // Добавление заказа
  ADD_STATE_SAVED_ORDER: (state, value) => {
    state.savedOrders.push(value);
    localStorage.setItem("savedLeadOrders", JSON.stringify(state.savedOrders));
  },
  // Удаление заказа
  DELETE_STATE_SAVED_ORDER: (state, value) => {
    state.savedOrders.splice(value, 1);
    localStorage.setItem("savedLeadOrders", JSON.stringify(state.savedOrders));
  },
  // Удаление всех заказов
  DELETE_ALL_STATE_SAVED_ORDER: (state) => {
    state.savedOrders = [];
    localStorage.setItem("savedLeadOrders", JSON.stringify([]));
  },
  // Вывод заказа
  EDIT_STATE_SAVED_ORDER: (state, value) => {
    state.currentOrder = state.savedOrders[value];
  },
  // Сохранение заказа
  SAVE_STATE_SAVED_ORDER: (state, value) => {
    state.savedOrders[value.id] = value.order;
    localStorage.setItem("savedLeadOrders", JSON.stringify(state.savedOrders));
  },
  // Изменение количества машин
  CHANGE_STATE_SAVED_ORDER_COUNT: (state, value) => {
    state.savedOrders[value.id].carCount = value.count;
    state.savedOrders[value.id].carSum =
      state.savedOrders[value.id].sum * value.count;
    localStorage.setItem("savedLeadOrders", JSON.stringify(state.savedOrders));
  },
  // Сохранения данных заказа
  SAVE_STATE_SAVED_ORDER_DATA: (state, value) => {
    state.savedOrdersData = value;
  },
  // Сохранения данных заказа
  CHANGE_STATE_ORDER_PAYMENT_ID: (state, value) => {
    state.paymentId = value;
    localStorage.setItem("paymentId", value);
  }
};

const actions = {
  // Добавление заказа
  addOrdersState({ commit }, value) {
    commit("ADD_STATE_SAVED_ORDER", value);
  },
  // Удаление заказа
  deleteOrdersState({ commit }, value) {
    commit("DELETE_STATE_SAVED_ORDER", value);
  },
  // Удаление всех заказов
  deleteAllOrdersState({ commit }) {
    commit("DELETE_ALL_STATE_SAVED_ORDER");
  },
  // Вывод заказа
  editOrdersState({ commit }, value) {
    commit("EDIT_STATE_SAVED_ORDER", value);
  },
  // Сохранение заказа
  saveOrdersState({ commit }, value) {
    commit("SAVE_STATE_SAVED_ORDER", value);
  },
  // Изменение количества машин
  changeOrdersStateCount({ commit }, value) {
    commit("CHANGE_STATE_SAVED_ORDER_COUNT", value);
  },
  // Сохранения данных заказа
  saveOrdersDataState({ commit }, value) {
    commit("SAVE_STATE_SAVED_ORDER_DATA", value);
  },
  // Добавление заказа
  changeOrdersPaymentId({ commit }, value) {
    commit("CHANGE_STATE_ORDER_PAYMENT_ID", value);
  }
};

export default createStore({
  state,
  getters: {},
  mutations,
  actions,
  modules: {}
});
