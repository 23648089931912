export default {
  methods: {
    // Очистка формы заказа
    clearForm() {
      this.order = {};
      this.activeCar = 1;
      this.activeType = 2;
      this.orderId = null;
      this.order.km = 0;
      // this.costTarif = 0;
      this.addStartRoute();
    },
    resetForm() {
      this.clearForm();
    },
    // Получение суммы заказа
    getCostTarif(id) {
      this.costTarif = id;
    },
    // Создание и редактирование заказа
    saveOrder() {
      this.order.carName = this.selectedCar.name;
      this.order.carNameShort = this.selectedCar.short;
      this.order.carImage = this.selectedCar.image;
      this.order.carType = this.selectedType.name;
      this.order.carTypeShort = this.selectedType.short;
      this.order.car = this.activeCar;
      this.order.type = this.activeType;
      this.order.sum = this.costTarif;
      this.isPayd = false;
      // Создание
      if (this.orderId === null) {
        this.order.carCount = 1;
        this.order.carSum = this.costTarif;
        this.addOrders(this.order);
      } else {
        this.order.carSum = this.costTarif * this.order.carCount;
        this.saveOrders({
          id: this.orderId,
          order: this.order
        });
      }
      this.clearForm();
    },
    // Редактирование заказа
    editOrder(id) {
      this.orderId = id;
      this.order = this.currentOrder;
      this.activeCar = this.currentOrder.car;
      this.activeType = this.currentOrder.type;
    }
  }
};
