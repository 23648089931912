export default {
  computed: {
    // Сумма всех заказов
    sumLead() {
      const temp = [];
      this.savedOrders.map((item) => temp.push(item.carSum));
      return this.sumColls(temp);
    },
    // Сумма всех заказов без разделителя
    sumLeadWithout() {
      const temp = [];
      this.savedOrders.map((item) => temp.push(item.carSum));
      return this.sumCollsWithout(temp);
    },
    // Создание комментариев для BITRIX по заказу
    createCommentsBitrix() {
      const temp = [],
        paymentData = this.savedOrdersData.paymentId
          ? `\nID платежа - ${this.savedOrdersData.paymentId}`
          : ``;
      temp.push(
        `Данные заказчика:\nТелефон: ${this.savedOrdersData.phone}.\nEmail: ${
          this.savedOrdersData.email
        }\nСпособ оплаты: ${this.savedOrdersData.namePayd + paymentData}\n\n`
      );
      this.savedOrders.map((item, index) => {
        const tempRoutes = [];
        if (item.routes) {
          item.routes.map((item) => tempRoutes.push(item.address));
        }
        const date = item.createDate ? ` Дата подачи: ${item.createDate}.` : ``,
          time = item.createTime ? ` Время подачи: ${item.createTime}.` : ``,
          routes =
            tempRoutes.length > 0
              ? ` Адреса по маршруту:\n${tempRoutes.join("\n")}.`
              : ``,
          comment = item.comment ? ` Комментарий: ${item.comment}.` : ``;
        temp.push(
          `Заказ ${index + 1}.\n
              ${date + time}\n
              ${item.carType} | ${item.carName}.\n
              ${routes}\n
              Количество машин: ${item.carCount}. Стоимость: ${
            item.carSum
          } руб.\n
              ${comment}\n\n`
        );
      });
      temp.push(`Итого: ${this.sumLead} руб.`);
      return temp.join("\n");
    },
    // Создание шаблона письма
    //   createTemplateEmail() {
    //     const temp = [];
    //     temp.push(`
    //     <!DOCTYPE html>
    //     <html lang="ru">
    //     <head>
    //       <meta charset="UTF-8">
    //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
    //       <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //       <link rel="preconnect" href="https://fonts.googleapis.com">
    //       <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    //       <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    //       <title>Подтверждение заказа tkglp.ru</title>
    //     </head>
    //     <body
    //       style="box-sizing: border-box; font-family: 'Montserrat', sans-serif; font-size: 12px; font-weight: normal; margin: 0; padding: 0;">
    //       <table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse">
    //         <tr>
    //           <td>
    //             <table cellpadding="0" cellspacing="0" width="1000" style="border-collapse: collapse; margin: 0 auto">
    //               <tbody>
    //                 <tr>
    //                   <th style="padding: 10px; text-align: left">
    //                     <img style="width: 130px"
    //                       src="https://static.tildacdn.com/tild3761-3562-4231-a237-343066333662/Group_34.png" alt="Logo" />
    //                   </th>
    //                   <th>
    //                     <h1>Подтверждение заказа tkglp.ru</h1>
    //                   </th>
    //                 </tr>
    //                 <tr>
    //                   <th colspan="2">
    //                     <img style="width: 100%" src="https://static.tildacdn.com/tild6465-3731-4030-a365-333539653764/_20_.png"
    //                       alt="Car" />
    //                   </th>
    //                 </tr>
    //                 <tr>
    //                   <td colspan="2">
    //                     <h2>Здравствуйте. Подтверждаем ваш заказ на перевозку, сделанный на сайте <a
    //                         href="https://tkglp.ru">транспортной компании GLP</a></h2>
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <td colspan="2" style="padding: 10px 0; text-align: left">
    //                     <strong>Способ оплаты:</strong> ${this.savedOrdersData.namePayd}
    //                   </td>
    //                 </tr>
    //                 <tr>
    //                   <td colspan="2">
    //                     <h2 style="margin: 20px auto">Заказанные позиции</h2>
    //                   </td>
    //                 </tr>
    //               </tbody>
    //             </table>
    //             <table cellpadding="0" cellspacing="0" width="1000"
    //               style="border: 1px solid #000; border-collapse: collapse; margin: 0 auto">
    //               <tbody>
    //                 <tr>
    //                   <th style="border: 1px solid #000; padding: 5px">№</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Грузо-<br>подъем<br>ность</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Тип кузова</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Дата подачи</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Время подачи</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Адреса по маршруту</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Комментарий</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Коли<br>чество</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Стоимость<br>за единицу, руб.</th>
    //                   <th style="border: 1px solid #000; padding: 5px">Стоимость итого, руб.</th>
    //                 </tr>`);
    //     this.savedOrders.map((item, index) => {
    //       const tempRoutes = [];
    //       if (item.routes) {
    //         item.routes.map((item) => tempRoutes.push(item.address));
    //       }
    //       temp.push(
    //         `<tr>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: center">${
    //           index + 1
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: center">${
    //           item.carName
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: center">${
    //           item.carType
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: center">${
    //           item.createDate ? item.createDate : ""
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: center">${
    //           item.createTime ? item.createTime : ""
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px">
    //           <ul style="padding: 0 10px">`
    //       );
    //       item.routes.map((item) => {
    //         if (item.address) {
    //           temp.push(`<li>${item.address}</li>`);
    //         }
    //       });
    //       temp.push(`
    //           </ul>
    //         </td>
    //         <td style="border: 1px solid #000; padding: 10px">${
    //           item.comment ? item.comment : ""
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: center">${
    //           item.carCount
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: right">${
    //           item.sum
    //         }</td>
    //         <td style="border: 1px solid #000; padding: 10px; text-align: right">${
    //           item.carSum
    //         }</td>
    //       </tr>`);
    //     });
    //     temp.push(`<tr>
    //                 <th colspan="9" style="border: 1px solid #000; padding: 10px; text-align: left">Итоговая стоимость заказа,
    //                   руб.</th>
    //                 <th style="border: 1px solid #000; padding: 10px; text-align: right">${this.sumLead}</th>
    //               </tr>
    //             </tbody>
    //           </table>
    //           <table cellpadding="0" cellspacing="0" width="1000" style="border-collapse: collapse; margin: 20px auto">
    //             <tbody>
    //               <tr>
    //                 <td style="font-size: 14px">
    //                   <div style="margin: 1px 0">С уважением,</div>
    //                   <div style="margin: 1px 0">Важенин Вадим</div>
    //                   <div style="margin: 1px 0">Менеджер по развитию</div>
    //                   <div style="margin: 1px 0">ООО "Джи Эл Пи"</div>
    //                   <div style="margin: 1px 0"><span style="color: #2222cc">Москва, ул. Енисейская, д.46, корп 2</span>
    //                   </div>
    //                   <div style="margin: 1px 0">+7 (985) 580 69 52</div>
    //                   <div style="margin: 1px 0"><a style="color: #1155cc" href="https://tkglp.ru">https://tkglp.ru</a></div>
    //                   <div style="margin: 15px 0">
    //                     <img style="width: 130px"
    //                       src="https://static.tildacdn.com/tild3761-3562-4231-a237-343066333662/Group_34.png" alt="Logo" />
    //                   </div>
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </td>
    //       </tr>
    //     </table>
    //   </body>
    // </html>`);
    //     return temp.join("");
    //   }
    // }
    createTemplateEmail() {
      const temp = [],
        paymentData = this.savedOrdersData.paymentId
          ? ` (ID платежа - ${this.savedOrdersData.paymentId})`
          : ``;
      temp.push(`
    <!DOCTYPE html>
    <html lang="ru">      
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
      <title>Подтверждение заказа tkglp.ru</title>
    </head>      
    <body
      style="box-sizing: border-box; font-family: 'Montserrat', sans-serif; font-size: 90%; font-weight: normal; margin: 0; padding: 0;">
      <table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; min-width: 300px">
        <tr>
          <td style="padding: 10px">
            <table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse">
              <tbody>
                <tr>
                  <th style="padding: 10px; text-align: left">
                    <img style="max-width: 200px; width: 100%" src="https://static.tildacdn.com/tild3761-3562-4231-a237-343066333662/Group_34.png" alt="Logo" />
                  </th>
                </tr>
                <tr style="border-bottom: 1px dashed #000000">
                  <td>
                    <h3>Здравствуйте. Подтверждаем ваш заказ на перевозку,<br>сделанный на сайте <a
                    href="https://tkglp.ru">транспортной компании GLP</a></h3>
                    <h4>Способ оплаты:<span style="font-weight: normal">&nbsp;${
                      this.savedOrdersData.namePayd + paymentData
                    }</span></h4>
                    <h4 style="margin: 20px auto">Позиции заказа:&nbsp;${
                      this.savedOrders.length
                    }</h4>
                  </td>
                </tr>`);
      this.savedOrders.map((item, index) => {
        const tempRoutes = [];
        if (item.routes) {
          item.routes.map((item) => tempRoutes.push(item.address));
        }
        temp.push(
          `<tr style="border-bottom: 1px dashed #000000">
            <td>
        <h4>
          Грузовой транспорт&nbsp;${index + 1}<br/>
          Грузоподъемность: <span style="font-weight: normal">&nbsp;${
            item.carName
          }</span><br/>
          Тип кузова: <span style="font-weight: normal">&nbsp;${
            item.carType
          }</span><br/>
          Дата подачи: <span style="font-weight: normal">&nbsp;${
            item.createDate ? item.createDate : ""
          }</span><br/>
          Время подачи: <span style="font-weight: normal">&nbsp;${
            item.createTime ? item.createTime : ""
          }</span><br/>
          Адреса по маршруту:
          <ul style="font-weight: normal; list-style: none; margin: 5px 0 0 -30px">`
        );
        item.routes.map((item) => {
          if (item.address) {
            temp.push(`<li>${item.address}</li>`);
          }
        });
        temp.push(`
          </ul>
          Комментарий:<br />
          <span style="font-weight: normal">${
            item.comment ? item.comment : ""
          }</span><br /><br />
          Количество транспорта: <span style="font-weight: normal">&nbsp;${
            item.carCount
          }</span><br/>
          Стоимость за единицу: <span style="font-weight: normal">&nbsp;${
            item.sum
          } руб.</span><br/>
          Стоимость итого: <span style="font-weight: normal">&nbsp;${
            item.carSum
          } руб.</span><br/>
        </h4>
        </td>
      </tr>`);
      });
      temp.push(`
              <tr>
                <td>
                  <h4 style="margin: 20px auto">Итоговая стоимость заказа:<br /><span style="font-weight: normal">&nbsp;${this.sumLead} руб.</span></h4>
                </td>
              </tr>
            </tbody>
          </table>
          <table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse; margin: 20px auto">
            <tbody>
              <tr>
                <td>
                  <div style="margin: 1px 0">С уважением,</div>
                  <div style="margin: 1px 0">Важенин Вадим</div>
                  <div style="margin: 1px 0">Менеджер по развитию</div>
                  <div style="margin: 1px 0">ООО "Джи Эл Пи"</div>
                  <div style="margin: 1px 0"><span style="color: #2222cc">Москва, ул. Енисейская, д.46, корп 2</span>
                  </div>
                  <div style="margin: 1px 0">+7 (985) 580 69 52</div>
                  <div style="margin: 1px 0"><a style="color: #1155cc" href="https://tkglp.ru">https://tkglp.ru</a></div>
                  <div style="margin: 15px 0">
                    <img style="max-width: 200px; width: 100%"
                      src="https://static.tildacdn.com/tild3761-3562-4231-a237-343066333662/Group_34.png" alt="Logo" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>`);
      return temp.join("");
    }
  }
};
