export default {
  data() {
    return {
      tarif: [
        {
          id: 1,
          car: 1,
          type: 1,
          time: "4+1",
          cost: 4000,
          extra: 540,
          mkad: 20
        },
        {
          id: 2,
          car: 2,
          type: 1,
          time: "4+1",
          cost: 4000,
          extra: 540,
          mkad: 20
        },
        {
          id: 3,
          car: 3,
          type: 1,
          time: "4+1",
          cost: 4200,
          extra: 585,
          mkad: 20
        },
        {
          id: 4,
          car: 4,
          type: 1,
          time: "5+1",
          cost: 6375,
          extra: 657,
          mkad: 28
        },
        {
          id: 5,
          car: 5,
          type: 1,
          time: "7+1",
          cost: 7625,
          extra: 855,
          mkad: 38
        },
        {
          id: 6,
          car: 6,
          type: 1,
          time: "7+1",
          cost: 10080,
          extra: 1035,
          mkad: 43
        },
        {
          id: 7,
          car: 7,
          type: 1,
          time: "7+1",
          cost: 13110,
          extra: 1300,
          mkad: 50
        },
        {
          id: 8,
          car: 1,
          type: 2,
          time: "4+1",
          cost: 4000,
          extra: 540,
          mkad: 20
        },
        {
          id: 9,
          car: 2,
          type: 2,
          time: "4+1",
          cost: 4000,
          extra: 540,
          mkad: 20
        },
        {
          id: 10,
          car: 3,
          type: 2,
          time: "4+1",
          cost: 4200,
          extra: 585,
          mkad: 20
        },
        {
          id: 11,
          car: 4,
          type: 2,
          time: "5+1",
          cost: 6375,
          extra: 657,
          mkad: 28
        },
        {
          id: 12,
          car: 5,
          type: 2,
          time: "7+1",
          cost: 7625,
          extra: 855,
          mkad: 38
        },
        {
          id: 13,
          car: 6,
          type: 2,
          time: "7+1",
          cost: 10080,
          extra: 1035,
          mkad: 43
        },
        {
          id: 14,
          car: 7,
          type: 2,
          time: "7+1",
          cost: 13110,
          extra: 1300,
          mkad: 50
        },
        {
          id: 15,
          car: 1,
          type: 3,
          time: "4+1",
          cost: 4000,
          extra: 600,
          mkad: 20
        },
        {
          id: 16,
          car: 2,
          type: 3,
          time: "4+1",
          cost: 4000,
          extra: 600,
          mkad: 20
        },
        {
          id: 17,
          car: 3,
          type: 3,
          time: "4+1",
          cost: 4400,
          extra: 650,
          mkad: 20
        },
        {
          id: 18,
          car: 4,
          type: 3,
          time: "5+1",
          cost: 7500,
          extra: 730,
          mkad: 28
        },
        {
          id: 19,
          car: 5,
          type: 3,
          time: "7+1",
          cost: 8970,
          extra: 950,
          mkad: 38
        },
        {
          id: 20,
          car: 6,
          type: 3,
          time: "7+1",
          cost: 11200,
          extra: 1150,
          mkad: 43
        },
        {
          id: 21,
          car: 7,
          type: 3,
          time: "7+1",
          cost: 13800,
          extra: 1520,
          mkad: 50
        },
        {
          id: 15,
          car: 1,
          type: 4,
          time: "4+1",
          cost: 5350,
          extra: 680,
          mkad: 21
        },
        {
          id: 16,
          car: 2,
          type: 4,
          time: "4+1",
          cost: 5350,
          extra: 680,
          mkad: 21
        },
        {
          id: 17,
          car: 3,
          type: 4,
          time: "4+1",
          cost: 6300,
          extra: 700,
          mkad: 21
        },
        {
          id: 18,
          car: 4,
          type: 4,
          time: "5+1",
          cost: 7700,
          extra: 750,
          mkad: 32
        },
        {
          id: 19,
          car: 5,
          type: 4,
          time: "7+1",
          cost: 9400,
          extra: 1050,
          mkad: 42
        },
        {
          id: 20,
          car: 6,
          type: 4,
          time: "7+1",
          cost: 13500,
          extra: 1300,
          mkad: 48
        },
        {
          id: 21,
          car: 7,
          type: 4,
          time: "7+1",
          cost: 16500,
          extra: 1700,
          mkad: 55
        }
      ]
    };
  }
};
