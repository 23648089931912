export default {
  methods: {
    // Начальный адрес
    addStartRoute() {
      this.order.routes = [
        {
          name: "Адрес подачи",
          title: "Укажите адрес",
          address: ""
        }
      ];
    },
    // Добавление адреса
    addOrderRoute() {
      this.order.routes.push({
        name: `Адрес ${this.order.routes.length}`,
        title: "Укажите адрес",
        address: ""
      });
    },
    // Удаление адреса
    removeOrderRoute(idx) {
      if (this.order.routes.length > 1) {
        this.order.routes.splice(idx, 1);
        this.chageRouteTurn();
      }
    },
    // Пересчитываем сортировку в массиве адресов
    chageRouteTurn() {
      this.order.routes.map((item, index) => {
        if (index === 0) {
          item.name = `Адрес подачи`;
        } else {
          item.name = `Адрес ${index}`;
        }
      });
    }
  }
};
