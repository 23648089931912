<template>
  <div class="form-textarea">
    <label
      v-if="label"
      :style="`width: ${width}px`"
      :class="{ invalid: isInvalid }"
      :for="id"
      >{{ label }}</label
    >
    <textarea
      :class="{ invalid: isInvalid }"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      :readonly="readonly"
      :value="modelValue"
      :style="styles"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "FormTextarea",
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: ""
    },
    trimData: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    styles: {
      type: String,
      default: ""
    },
    width: String,
    isInvalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: "textarea-" + Math.random()
    };
  },
  computed: {
    trimValue() {
      if (this.modelValue && this.trimData) {
        return this.modelValue.trim();
      } else {
        return this.modelValue;
      }
    }
  }
};
</script>

<style lang="scss">
.form-textarea {
  margin-top: 15px;
  position: relative;

  label {
    background-color: $color-white;
    font-size: 12px;
    font-weight: 500;
    left: 10px;
    padding: 5px;
    position: absolute;
    top: -12px;

    &.invalid {
      color: $color-red;
    }
  }

  textarea {
    background-color: $color-white !important;
    border: 1px solid $color-basic;
    border-radius: 8px;
    color: $color-basic;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    outline: none;
    overflow: auto;
    padding: 8px 9px 8px 16px;
    resize: none;
    width: 100%;

    &::placeholder {
      color: $color-grey !important;
    }

    &:active {
      outline: none;
    }

    &:focus {
      border: 1px solid $color-basic;
      outline: none;
      background: $color-white !important;
    }

    &.invalid {
      border: 1px solid $color-red;
      color: $color-red;

      &::placeholder {
        color: $color-red !important;
      }
    }
  }
}

textarea:-internal-autofill-selected,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  background-color: $color-white !important;
  color: $color-basic !important;
}
</style>
